<template>
    <div class="404-page">
        404 Not Found!
    </div>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style scoped>

</style>
